// axios二次封装
import axios from "axios";
import qs from "qs";
import router from '@/router'
import {
	Message
} from "element-ui";
/**
 * 参考文档 https://github.com/axios/axios
 */
const config = {
	baseURL: "https://accident.shs.broing.cn/",

};

class Request {
	constructor(params) {
		this.baseURL = params.baseURL;
	}

	// 获取axios实例的配置
	getInsideConfig() {
		const config = {
			baseURL: this.baseURL,
			headers: {
				// "Content-Type": "application/json;charset=utf-8",
				"Content-Type": "application/x-www-form-urlencoded",
				// "Authorization": token
				"token": window.localStorage.getItem("token")
			},
			timeout: 120000,
			withCredentials: true,
		};
		return config;
	}

	interceptors(instance) {
		// 请求拦截器
		instance.interceptors.request.use(
			(config) => {
				// Do something before request is sent
				return config;
			},
			(error) => {
				// Do something with request errorv
				return Promise.reject(error);
			}
		);

		// 响应拦截器
		instance.interceptors.response.use(
			(res) => {
				console.log(888888, router)
				// Any status code that lie within the range of 2xx cause this function to trigger
				// Do something with response data
				if (res.status == 401) {
					// if (router.currentRoute.path !== "/") {
					//     Message.warning({ message: "Status expired, please log in again" });
					//     localStorage.clear();
					//     router.replace("/");
					// }
					window.localStorage.removeItem('token');
					console.log('清除token', window.localStorage.getItem('token'));
					this.$router.replace({
						path: '/'
					});
					this.$router.go(0);
					return res;
				}
				switch (res.status) {
					case 500:
						Message.error({
							message: res.data.msg
						});
						break;
				}

				return res;
			},
			(error) => {
				// Any status codes that falls outside the range of 2xx cause this function to trigger
				// Do something with response error
				// Message.error({
				// 	message: "网络请求失败，请稍后再试"
				// });
				console.log(111,error.response.status)
				if (error.response.status == 401) {
					window.localStorage.removeItem('token');
					console.log('清除token', window.localStorage.getItem('token'));
					router.replace({
						path: '/'
					});
					return res;
				}
				return Promise.reject(error);

			}
		);
	}

	// 创建实例
	request(options) {
		const instance = axios.create();
		const newOptions = Object.assign(this.getInsideConfig(), options);
		this.interceptors(instance);
		// 等价于 instance.request(newOptions)
		return instance(newOptions);
	}

	get(url, config) {
		const options = Object.assign({
				method: "get",
				url: url,
			},
			config
		);
		return this.request(options);
	}

	post(url, data, type) {
		return this.request({
			method: "post",
			url: url,
			data: type ? data : qs.stringify(data),
			headers: {
				"Content-Type": type ?
					type : "application/x-www-form-urlencoded",
				// "Authorization": 'Bearer ' + token ? token : ""
				"token": 'Bearer ' + window.localStorage.getItem("token") ? window.localStorage.getItem(
					"token") : ""
			},
		});
	}

	put(url, data, type) {
		return this.request({
			method: "put",
			url: url,
			data: type ? data : qs.stringify(data),
			headers: {
				"Content-Type": type ?
					type : "application/x-www-form-urlencoded",
				"Authorization": 'Bearer ' + window.localStorage.getItem("token") ? window.localStorage
					.getItem("token") : ""
			},
		});
	}

	delete(url, data, type) {
		return this.request({
			method: "delete",
			url: url,
			data: type ? data : qs.stringify(data),
			headers: {
				"Content-Type": type ?
					type : "application/x-www-form-urlencoded",
				"Authorization": 'Bearer ' + window.localStorage.getItem("token") ? window.localStorage
					.getItem("token") : ""
			},
		});
	}
}

const request = new Request({
	baseURL: config.baseURL,
});

export default request;
