<template>
	<div class="footer l-flex-center">
		<div class="footer-box">
			<div class="footer-show l-flex-between">
				<div class="footer-our">
					<div class="title-size">关于我们</div>
					<div class="word-color footer-gap">
						<div v-html="ourText"></div>
					</div>
				</div>
				<div class="footer-contact">
					<div class="title-size">联系我们</div>
					<div class="word-color footer-gap l-flex-start">
						<div class="contact-title">地址</div>
						<div>{{address}}</div>
					</div>
					<div class="word-color footer-gap l-flex-start">
						<div class="contact-title">电话</div>
						<div>{{phone}}</div>
					</div>
				</div>
				<div class="footer-wechat">
					<img class="footer-code" :src="code" alt="" />
					<div class="wechat-text">微信小程序</div>
				</div>
			</div>
			<div class="footer-num l-flex-center subword-color">
				<div class="num-item is-hover" @click="toUrl">ICP备案：{{ICPnum}}</div>
				<div class="num-item">{{policeNum}}</div>
			</div>
		</div>
	</div>
</template>


<script>
	export default {
		name: "footerView",
		data() {
			return {
				ourText: '',
				address: '',
				phone: '',
				code: '',
				ICPnum: '',
				policeNum: ''
			}
		},
		methods: {
			toUrl() {
				window.open('https://beian.miit.gov.cn', "_blank");
			}
		},
		created() {
			this.$postAction('api/common/system', {
				s_id: 7
			}).then(res => {
				// console.log('关于我们', res);
				this.ourText = res.data.data;
			});
			this.$postAction('api/common/system', {
				s_id: 8
			}).then(res => {
				// console.log('地址', res);
				this.address = res.data.data;
			});
			this.$postAction('api/common/system', {
				s_id: 9
			}).then(res => {
				// console.log('电话', res);
				this.phone = res.data.data;
			});
			this.$postAction('api/common/system', {
				s_id: 3
			}).then(res => {
				// console.log('备案号', res);
				this.ICPnum = res.data.data;
			});
			this.$postAction('api/common/system', {
				s_id: 4
			}).then(res => {
				// console.log('公网号', res);
				this.policeNum = res.data.data;
			});
			this.$postAction('api/pc/qrcode').then(res => {
				// console.log('小程序码', res);
				this.code = res.data.data;
			})
		}
	}
</script>


<style lang="scss">
	.footer {
		background-color: #fff;
	}

	.footer-box {
		width: 1200px;

		.l-flex-between {
			align-items: flex-start;
		}
	}

	.footer-show {
		padding-top: 40px;
		padding-bottom: 25px;
		border-bottom: 2px solid #F5F8FA;
	}

	.footer-our {
		width: 510px;
		margin-right: 90px;
	}

	.footer-contact {
		width: 510px;
		margin-right: 90px;
	}

	.contact-title {
		margin-right: 12px;
	}

	.footer-gap {
		margin-top: 16px;
	}

	.footer-code {
		width: 80px;
		height: 80px;
	}

	.wechat-text {
		text-align: center;
		margin-top: 8px;
		font-weight: bold;
	}

	.footer-num {
		height: 60px;
	}

	.num-item {
		margin-right: 24px;
	}
</style>
