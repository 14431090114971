import axios from "./request";

const getAction = (url, params) => {
    return axios.get(url, params)
}

const postAction = (url, params, headers) => {
    return axios.post(url, params, headers)
}


export {
    getAction,
    postAction
}