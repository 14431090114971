import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
Vue.use(ElementUI)

import footerView from './components/footer/index.js'
Vue.use(footerView)

import headerView from './components/header/index.js'
Vue.use(headerView)

import EventBus from './common/bus.js'
Vue.use(EventBus)
Vue.prototype.$EventBus = EventBus

import {
	getAction,
	postAction
} from './common/api.js'
Vue.prototype.$getAction = getAction;
Vue.prototype.$postAction = postAction;

Vue.config.productionTip = false



new Vue({
	router,
	store,
	render: h => h(App)
}).$mount('#app')
