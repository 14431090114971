<template>
	<div class="header l-flex-center">
		<div class="header-content l-flex-between">
			<div class="l-flex-start">
				<img class="text-logo" src="@/assets/text_logo.png" @click="toHome" alt="" />
				<div class="search l-flex-start">
					<el-input class="el-input" v-model="searchData" placeholder="输入关键字"></el-input>
					<div class="search-btn l-flex-center" @click="search">搜索案例</div>
				</div>
			</div>
			<div class="login l-flex-start" @click="toUserCenter">
				<div class="login-text">{{nickname}}</div>
				<div class="login-avater">
					<img style="width: 100%;height: 100%;border-radius: 50%;" :src="avatar" alt="" />
					<img class="ic-vip" src="@/assets/ic_vip.png" v-if="vipStatus==1" alt="">
				</div>
			</div>
		</div>
	</div>
</template>


<script>
	export default {
		name: 'headerView',
		props: {
			searchKey: [String, Number]
		},
		data() {
			return {
				avatar: '',
				nickname: '',
				vipStatus: 0,
				searchData: this.searchKey
			}
		},
		methods: {
			search() {
				this.$emit('change', this.searchData);
			},
			toHome() {
				this.$router.replace({
					path: '/'
				});
			},
			toUserCenter() {
				this.$emit('jump');
			}
		},
		created() {
			this.$EventBus.$on('changeHeader', () => {
				this.$postAction('api/person/index').then(res => {
					this.avatar = res.data.data.avatar;
					this.nickname = res.data.data.nickname;
					this.vipStatus = res.data.data.is_vip;
				});
			});
			this.$postAction('api/person/index').then(res => {
				this.avatar = res.data.data.avatar;
				this.nickname = res.data.data.nickname;
				this.vipStatus = res.data.data.is_vip;
			});
		}
	}
</script>

<style lang="scss">
	.header {
		background-color: #fff;
		height: 80px;
	}

	.header-content {
		width: 1200px;
	}

	.text-logo {
		width: 122px;
		height: 22px;
	}

	.search {
		margin-left: 30px;
	}

	.el-input {
		width: 370px;
	}

	.el-input__inner {
		height: 44px;
		border-top-right-radius: 0;
		border-bottom-right-radius: 0;
	}

	.search-btn {
		width: 109px;
		height: 44px;
		border-top-right-radius: 6px;
		border-bottom-right-radius: 6px;
		background-color: #015EEA;
		color: #fff;
	}

	.login-text {
		font-weight: bold;
		margin-right: 8px;
	}

	.login-avater {
		position: relative;
		width: 40px;
		height: 40px;
		border-radius: 50%;

		.ic-vip {
			position: absolute;
			right: 0;
			bottom: 0;
			width: 17px;
			height: 17px;
		}
	}
</style>
