import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [{
	path: '/',
	name: 'home',
	meta: {
		keepAlive: true
	},
	component: () => import('../views/home.vue')
}, {
	path: '/search',
	name: 'search',
	meta: {
		keepAlive: true
	},
	component: () => import('../views/search.vue')
}, {
	path: '/caseDetail',
	name: 'caseDetail',
	meta: {
		keepAlive: false
	},
	component: () => import('../views/caseDetail.vue')
}, {
	path: '/userCenter',
	name: 'userCenter',
	meta: {
		keepAlive: true
	},
	component: () => import('../views/userCenter.vue')
}]

const router = new VueRouter({
	routes
})

export default router
